import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { FaLock, FaSpinner } from 'react-icons/fa';
import PageLayout from '@/components/PageLayout';
import { supabase } from '@/lib/supabase';
import EmailVerificationMessage from './EmailVerificationMessage';
import PasswordStrengthIndicator from '@/components/ui/PasswordStrengthIndicator';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [signupComplete, setSignupComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    email?: string;
    password?: string;
  }>({});

  const validateEmail = (email: string): string => {
    if (!email) return 'Email is required';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return 'Please enter a valid email address';
    return '';
  };

  const validatePassword = (password: string): string => {
    if (!password) return 'Password is required';
    if (isSignUp) {
      const checks = {
        length: password.length >= 8,
        hasUppercase: /[A-Z]/.test(password),
        hasLowercase: /[a-z]/.test(password),
        hasNumber: /[0-9]/.test(password),
        hasSpecial: /[^A-Za-z0-9]/.test(password),
      };

      const failedChecks = Object.entries(checks)
        .filter(([_, passes]) => !passes)
        .map(([check]) => {
          switch (check) {
            case 'length': return 'Password must be at least 8 characters';
            case 'hasUppercase': return 'Password must contain an uppercase letter';
            case 'hasLowercase': return 'Password must contain a lowercase letter';
            case 'hasNumber': return 'Password must contain a number';
            case 'hasSpecial': return 'Password must contain a special character';
            default: return '';
          }
        });

      if (failedChecks.length > 0) {
        return failedChecks[0]; // Return the first failed requirement
      }
    }
    return '';
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'email' | 'password'
  ) => {
    const value = e.target.value;
    if (field === 'email') {
      setEmail(value);
      const error = validateEmail(value);
      setValidationErrors(prev => ({ ...prev, email: error }));
    } else {
      setPassword(value);
      const error = validatePassword(value);
      setValidationErrors(prev => ({ ...prev, password: error }));
    }
  };

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const emailError = validateEmail(email);
    const passwordError = !isResetMode ? validatePassword(password) : '';
    
    setValidationErrors({
      email: emailError,
      password: passwordError
    });

    if (emailError || (!isResetMode && passwordError)) {
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      if (isResetMode) {
        const { error } = await supabase.auth.resetPasswordForEmail(email);
        if (error) throw error;
        setResetSent(true);
      } else if (isSignUp) {
        const { error } = await supabase.auth.signUp({ email, password });
        if (error) throw error;
        setSignupComplete(true);
      } else {
        const { error } = await supabase.auth.signInWithPassword({ email, password });
        if (error) throw error;
        navigate('/');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    if (signupComplete) {
      return <EmailVerificationMessage type="signup" email={email} />;
    }

    if (resetSent) {
      return (
        <div className="text-center">
          <EmailVerificationMessage type="reset" email={email} />
          <Button 
            onClick={() => {
              setIsResetMode(false);
              setResetSent(false);
            }}
            variant="primary"
            className="w-full py-3 mt-6"
            disabled={isLoading}
          >
            Back to Login
          </Button>
        </div>
      );
    }

    return (
      <>
        <form onSubmit={handleAuth} className="space-y-6">
          <div>
            <label htmlFor="email" className="block mb-2 font-medium text-zinc-300">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => handleInputChange(e, 'email')}
              required
              disabled={isLoading}
              aria-invalid={!!validationErrors.email}
              aria-describedby={validationErrors.email ? "email-error" : undefined}
              className={`w-full px-4 py-3 bg-transparent border-2 rounded-xl leading-none text-white text-lg focus:outline-none transition-all duration-300 disabled:opacity-50 ${
                validationErrors.email 
                  ? 'border-red-500/50 focus:border-red-500' 
                  : 'border-orange-500/50 focus:border-orange-500'
              }`}
            />
            {validationErrors.email && (
              <div id="email-error" className="text-red-500 text-sm mt-1">
                {validationErrors.email}
              </div>
            )}
          </div>
          {!isResetMode && (
            <div>
              <label htmlFor="password" className="block mb-2 font-medium text-zinc-300">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => handleInputChange(e, 'password')}
                required
                disabled={isLoading}
                aria-invalid={!!validationErrors.password}
                aria-describedby={validationErrors.password ? "password-error" : undefined}
                className={`w-full px-4 py-3 bg-transparent border-2 rounded-xl leading-none text-white text-lg focus:outline-none transition-all duration-300 disabled:opacity-50 ${
                  validationErrors.password 
                    ? 'border-red-500/50 focus:border-red-500' 
                    : 'border-orange-500/50 focus:border-orange-500'
                }`}
              />
              {validationErrors.password && (
                <div id="password-error" className="text-red-500 text-sm mt-1">
                  {validationErrors.password}
                </div>
              )}
              {isSignUp && password.length > 0 && (
                <PasswordStrengthIndicator password={password} />
              )}
            </div>
          )}

          <div className="space-y-2">
            <Button 
              type="submit"
              variant="primary"
              className="w-full py-3"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2 h-4 w-4 inline" />
                  {isResetMode ? 'Sending...' : isSignUp ? 'Signing Up...' : 'Logging In...'}
                </>
              ) : (
                isResetMode ? 'Send Reset Link' : isSignUp ? 'Sign Up' : 'Login'
              )}
            </Button>
            {!isResetMode && !isSignUp && (
              <Button 
                onClick={() => setIsResetMode(true)}
                variant="secondary"
                className="w-full py-3 text-sm"
                disabled={isLoading}
              >
                Forgot Password?
              </Button>
            )}
          </div>
        </form>

        {!resetSent && (
          <div className="mt-8">
            <Button 
              onClick={() => {
                if (isResetMode) {
                  setIsResetMode(false);
                } else {
                  setIsSignUp(!isSignUp);
                  setSignupComplete(false);
                }
              }}
              variant="outline"
              className="w-full py-3"
              disabled={isLoading}
            >
              {isResetMode 
                ? 'Back to Login'
                : isSignUp 
                  ? 'Already have an account? Login' 
                  : 'Need an account? Sign Up'}
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <PageLayout title={isSignUp ? "Sign Up" : "Login"} icon={FaLock}>
      <div className="max-w-md mx-auto">
        {error && (
          <div className="text-red-500 text-sm mb-4">
            {error}
          </div>
        )}
        {renderForm()}
      </div>
    </PageLayout>
  );
};

export default Login;
