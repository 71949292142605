import React from 'react';
import { IconType } from 'react-icons';

interface PageLayoutProps {
  title: string;
  icon: IconType;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, icon: Icon, children }) => {
  return (
    <div className="container mx-auto px-4 py-8 animate-fadeIn">
      <div className="flex items-center justify-center mb-8">
        <Icon size={36} className="text-orange-500 mr-4" />
        <h1 className="text-4xl font-bold text-orange-500">{title}</h1>
      </div>
      <div className="max-w-3xl mx-auto">
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
