import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';

interface EmailVerificationMessageProps {
  type?: 'signup' | 'reset';
  email?: string;
}
const EmailVerificationMessage: React.FC<EmailVerificationMessageProps> = ({ type, email }) => {
  const [message, setMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleEmailConfirmation = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const confirmationType = params.get('type');

      if (confirmationType === 'email_confirmation' && token) {
        try {
          const { error } = await supabase.auth.verifyOtp({
            token_hash: token,
            type: 'email',
          });

          if (error) {
            setMessage('There was a problem verifying your email. Please try again.');
          } else {
            setIsSuccess(true);
            setMessage('Your email has been successfully verified! Welcome to FanFabric!');
            setTimeout(() => {
              navigate('/');
            }, 3000);
          }
        } catch (error) {
          setMessage('An unexpected error occurred. Please try again.');
        }
      }
    };

    handleEmailConfirmation();
  }, [location.search, navigate]);

  // If we have a token verification in progress, show the notification message
  if (message) {
    return (
      <div
        className={`fixed top-4 right-4 p-4 rounded-lg shadow-lg ${
          isSuccess ? 'bg-green-600' : 'bg-red-600'
        } text-white max-w-md z-50 animate-fade-in`}
      >
        <div className="flex items-center">
          {isSuccess ? (
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M5 13l4 4L19 7" />
            </svg>
          ) : (
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          )}
          <p>{message}</p>
        </div>
      </div>
    );
  }

  // If we have type and email props, show the instruction message
  if (type && email) {
    return (
      <div className="text-center">
        <h2 className="text-xl font-bold mb-4">
          {type === 'signup' ? 'Verify Your Email' : 'Check Your Email'}
        </h2>
        <p className="text-zinc-300">
          {type === 'signup'
            ? `We've sent a verification link to ${email}. Please check your email to verify your account.`
            : `We've sent password reset instructions to ${email}. Please check your email to reset your password.`}
        </p>
      </div>
    );
  }

  return null;
};

export default EmailVerificationMessage;
