import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '@/lib/supabase';
import { Session, User, AuthError } from '@supabase/supabase-js';

interface AuthContextType {
  session: Session | null;
  user: User | null;
  isAdmin: boolean;
  loading: boolean;
  signOut: () => Promise<{ error: AuthError | null }>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user ?? null);
      checkUserRole(session?.user ?? null);
    });
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
      checkUserRole(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const checkUserRole = async (user: User | null) => {
    setLoading(true);
    console.log('Starting checkUserRole for user:', user?.email);
    if (user) {
      try {
        // Log the query we're about to make
        console.log('Fetching profile for user ID:', user.id);
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        console.log('Full profile data:', data);
        console.log('Error if any:', error);

        if (error) {
          console.error('Error fetching user role:', error);
          setIsAdmin(false);
        } else {
          // Add more detailed logging
          console.log('Role check details:', {
            userId: user.id,
            roleFromDB: data?.role,
            isAdmin: data?.role === 'admin',
            fullProfile: data
          });
          setIsAdmin(data?.role === 'admin');
        }
      } catch (error) {
        console.error('Exception in checkUserRole:', error);
        setIsAdmin(false);
      }
    } else {
      console.log('No user provided to checkUserRole');
      setIsAdmin(false);
    }
    setLoading(false);
  };

  const signOut = () => supabase.auth.signOut();

  const value: AuthContextType = {
    session,
    user,
    isAdmin,
    loading,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
